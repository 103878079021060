import '@fortawesome/fontawesome-svg-core/styles.css'
import React, {useState} from "react"
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import logo from "../images/logo.svg"

const phone = <FontAwesomeIcon icon={faPhoneAlt} />
const email = <FontAwesomeIcon icon={faEnvelope} />


var flag = 0;
const Header = () => {
    const [style, setStyle] = useState("hidden");

    const changeStyle = () => {
        // Show mobile view navbar
        if (flag === 0){
            setStyle("showing");
            flag = 1;
        }
        // Hide mobile view navbar
        else{
            setStyle("hidden");
            flag = 0;
        }
    }

    

    return(
        <div>
            
            <div className="center-row" id="minibar-parent">
                <div className="minibar"> 
                    <li>
                    <img class = "logo" src={logo} alt="Logo Icon" height = "130" width = "130"></img>
                    </li>
                   
                    <li>
                        <span></span>
                    </li>
                    

                    <li>
                        <FontAwesomeIcon icon={faBars} onClick={changeStyle} id="menu"/>
                    </li>
                    
            
                    <div className="alignRight">
                        
                        <li>
                        <a href="tel:5307465730" id="phone">{phone}</a>
                        <a href="tel:5307465730" id="email">Call Us at 530-746-5730</a>
                        </li>
                        <li> 
                        <a href="mailto:info@coophomecare.coop">{email}</a>
                        <a href="mailto:info@coophomecare.coop">Email Us</a>
                        </li>
                        
                        
                    </div>
                </div>
            </div>
            
            <div className="center-row" id="navbar-parent">
                <nav className={style}>
                    <Link to="/">Home</Link>
                    <Link to="/ourStory">Our Story</Link>
                    <Link to="/services">Our Services</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/careers">Careers</Link>
                    {/*<Link to="/blog">Blog</Link>*/}
                </nav>
            </div>
        </div>
    )
}
export default Header
