import React from "react"
import { Link } from 'gatsby'
import fb from "../images/fb.svg"

function Footer(){
    return(
        <footer>
            <div id="footer-content"> 
                <div className="footerSection">
                    <h3> ADDRESS </h3>
                    <span>
                        979 F Street, Suite A-1
                        <br></br>
                        Davis, CA 95616
                    </span>
                </div>
                <div className="footerSection">
                    <h3> CONNECT WITH US </h3>
                    <span>
                        Phone: 530-746-5730 
                        <br></br>
                        Email: info@coophomecare.coop
                    </span>
                    <a href="https://m.facebook.com/homecare.coop" target="_blank" rel="noreferrer"> 
                        <img src={fb} alt= "Facebook Logo"></img>
                    </a>
                </div>
                <div className="footerSection">
                    <Link to="/services" id="find-footer">Find Care</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer